<i18n>
ru:
  additionalModifiersTitle: 'Дополнительные ингредиенты: '
  back: назад
  cartEmpty: Корзина пуста...
  cartEmptySelfService: Добавляйте свои любимые блюда.
  cartEmptyDelivery: 'Доставим бесплатно от суммы {minSum}.'
  cartEmptyNoType: >-
    Выберите способ получения заказа, чтобы мы смогли предложить актуальные для
    вас блюда.
  count: 'Нет товаров | {n} товар за | {n} товара за | {n} товаров за'
  deletedByUser: Удалено
  edit: Изменить
  nextStepButton: Продолжить
  removableIngredientsPrefix: '- '
  replace: Заменить
  restore: Восстановить
ua:
  additionalModifiersTitle: 'Додаткові інгредієнти: '
  back: назад
  cartEmpty: Кошик порожній...
  cartEmptySelfService: Додавайте свої улюблені страви.
  cartEmptyDelivery: 'Безкоштовна доставка від суми {minSum}.'
  cartEmptyNoType: >-
    Оберіть спосіб отримання замовлення, щоб ми могли запропонувати актуальні
    для вас страви.
  count: 'Нет позиций | {n} позиция за | {n} позиции за | {n} позиций за'
  deletedByUser: Вилучено
  edit: Змінити
  nextStepButton: Продовжити
  removableIngredientsPrefix: '- '
  replace: Замінити
  restore: Відновити
us:
  additionalModifiersTitle: 'Additionally: '
  back: back
  cartEmpty: Your cart is empty...
  cartEmptyDelivery: 'Free delivery for orders over {minSum}.'
  cartEmptyNoType: Select a delivery method to see dishes available for you.
  cartEmptySelfService: Add your favorite dishes.
  count: 'no items | one item for | {count} items for'
  deletedByUser: Removed
  edit: Edit
  nextStepButton: Continue
  removableIngredientsPrefix: '- '
  replace: Replace
  restore: Restore
</i18n>

<template>
  <div
    class="v-sidebar-content"
    v-if="clientStore.ClientState.data"
  >
    <div
      v-if="!isExtraSmall"
      class="v-sidebar-content__close"
    >
      <ui-icon
        color="white"
        :size="42"
        name="close"
        @click="closeFunction"
      />
    </div>
    <div
      v-if="!clientStore.ClientState.data.Cart || totalAmount === 0"
      class="v-sidebar-content--default v-sidebar-content--empty"
    >
      <div
        v-if="isExtraSmall"
        class="v-sidebar-content__back"
      >
        <ui-button
          :size="14"
          color="grey"
          type="text-with-button"
          width="content"
          :label="translate('sidebarContent.back')"
          :click-event="closeFunction"
        >
          <ui-icon
            disable-hover
            color="mono400"
            :size="24"
            name="chevronLeft"
          />
          <span
            class="v-color-mono-400 v-ml-xxs"
            v-html="translate('sidebarContent.back')"
          />
        </ui-button>
      </div>
      <div class="v-d-flex v-flex-column v-align-items-center">
        <div class="v-mb-md">
          <icon-manual-cart-empty class="v-sidebar-content__empty-icon" />
        </div>
        <h2
          class="v-mb-xs v-typography-title-2"
          v-html="translate('sidebarContent.cartEmpty')"
        />
        <template v-if="clientStore.courierDelivery">
          <div
            class="v-typography-text-4 v-color-mono-600 v-text-center"
            v-html="translate('sidebarContent.cartEmptySelfService')"
          />
          <i18n-t
            v-if="minSum > 0"
            class="v-typography-text-4 v-color-mono-600 v-text-center v-d-flex"
            keypath="sidebarContent.cartEmptyDelivery"
            scope="global"
            tag="div"
          >
            <template #minSum>
              <common-currency
                class="v-ml-xxs"
                :amount="minSum"
              />
            </template>
          </i18n-t>
        </template>
        <div
          v-else-if="clientStore.selfService || clientStore.inHall"
          class="v-typography-text-4 v-color-mono-600 v-text-center"
          v-html="translate('sidebarContent.cartEmptySelfService')"
        />
        <div
          v-else
          class="v-typography-text-4 v-color-mono-600 v-text-center"
          v-html="translate('sidebarContent.cartEmptyNoType')"
        />
      </div>
    </div>
    <div
      v-else
      class="v-sidebar-content--default v-d-flex v-flex-column v-justify-content-between"
    >
      <div class="v-sidebar-content__wrapper v-scrollbar v-pb-xxs">
        <div
          v-if="isExtraSmall"
          class="v-sidebar-content__back"
        >
          <ui-button
            :size="14"
            color="grey"
            type="text-with-button"
            width="content"
            :label="translate('sidebarContent.back')"
            :click-event="closeFunction"
          >
            <ui-icon
              disable-hover
              color="mono400"
              :size="24"
              name="chevronLeft"
            />
            <span
              class="v-color-mono-400 v-ml-xxs"
              v-html="translate('sidebarContent.back')"
            />
          </ui-button>
        </div>
        <div class="v-sidebar-content__top v-d-flex v-justify-content-between">
          <div class="v-d-flex">
            <span
              class="v-typography-title-2"
              :class="[
                totalAmountWithoutRemoved === 0
                  ? 'v-color-mono-600'
                  : 'v-color-mono-1000'
              ]"
              v-html="
                translate(
                  'sidebarContent.count',
                  { n: totalAmountWithoutRemoved },
                  totalAmountWithoutRemoved
                )
              "
            />
            <common-currency
              v-if="totalAmountWithoutRemoved > 0"
              :amount="
                clientStore.ClientState.data.Cart.ToPayWithMoneyActive.WithDiscount
              "
              class="v-typography-title-2 v-color-mono-1000 v-ml-xs"
            />
          </div>

          <ui-icon
            class-name="v-pointer"
            :size="24"
            color="secondary"
            name="delete"
            @click="() => clearCart()"
          />
        </div>
        <div class="v-sidebar-content__messages">
          <cart-rhudaur-messages />
        </div>
        <div
          class="v-sidebar-content__gifts-ranged"
          v-if="
            appConfig.VueSettingsPreRun.GiftsRangedShowInCart && giftsRangedVisible
          "
        >
          <cart-gifts-ranged />
        </div>
        <div
          class="v-sidebar-content__gifts-birthday"
          v-if="isLoggedIn && giftsBirthdayVisible"
        >
          <cart-rhudaur-birthday-gifts />
        </div>
        <div class="v-sidebar-content__items">
          <cart-rhudaur-content />
        </div>
        <div class="v-sidebar-content__additional-items">
          <cart-rhudaur-additional-items />
        </div>
        <div class="v-sidebar-content__recommended">
          <cart-recommended />
        </div>
      </div>
      <div class="v-sidebar-content__fixed-part">
        <div class="v-sidebar-content__promo-code">
          <cart-rhudaur-promo-code />
        </div>
        <div
          class="v-sidebar-content__partial_points"
          v-if="isLoggedIn && partialPayEnabled"
        >
          <cart-rhudaur-partial-points />
        </div>
        <div class="v-sidebar-content__summary">
          <cart-rhudaur-summary-page v-if="!items.every((item) => item.Removed)" />
        </div>
        <div class="v-sidebar-content__next-step">
          <transition
            appear
            mode="out-in"
            name="fade"
          >
            <div
              v-if="minSumNotReached"
              class="v-d-flex v-mb-sm"
              data-test-id="min-delivery-sum-text"
            >
              <span
                class="v-mr-xxs"
                v-html="translate('cartCommonPage.minDeliverySum')"
              />
              <common-currency
                :amount="
                  clientStore.ClientState.data?.DeliveryPaymentInfo
                    ?.ModifiedMinSum ?? 0
                "
                data-test-id="min-delivery-sum"
              />
            </div>
          </transition>
          <ui-button
            data-test-id="slide-small-basket-order-confirm"
            :click-event="nextStep"
            :disabled="clientStore.ClientState.data.FinalCalculatePrice === 0"
            :label="translate('sidebarContent.nextStepButton')"
            color="primary"
            type="fill"
            width="wide"
            :size="48"
          >
            <div class="v-d-flex v-justify-content-between v-w-100">
              <span v-html="translate('sidebarContent.nextStepButton')" />

              <common-currency
                :amount="clientStore.ClientState.data.FinalCalculatePrice"
              />
            </div>
          </ui-button>
        </div>
      </div>
    </div>
  </div>
  <arora-loader v-else />
</template>

<script setup lang="ts">
import type { DistrictsPrice } from '~types/addressStore'
import type { MessagesV2 } from '~types/clientStore'

import { useWindowSize } from '@arora/common'

import { MessageType } from '~api/consts'

defineProps<{ closeFunction: () => Promise<void> }>()

const addressStore = useAddressStore()
const clientStore = useClientStore()
const menuStore = useMenuStore()
const popupStore = usePopupStore()

const { isLoggedIn } = storeToRefs(useAccountStore())

const { minSumNotReached } = useAddressSelector()
const { translate } = useI18nSanitized()
const { items, totalAmount, totalAmountWithoutRemoved } = useCart()
const { isExtraSmall } = useWindowSize()
const appConfig = useAppConfig()

onMounted(() => {
  menuStore.initGiftsRanged()
})

function clearCart(): void {
  popupStore.openPopup({
    openPopupOutQueue: true,
    popupClosable: true,
    popupName: 'cartCleanupPopup'
  })
}

const minSum = computed<number>(() => {
  const prices = [...addressStore.DeliveryZones.values()].flatMap(
    (dz: DistrictsPrice) => dz.PriceRules
  )

  const rulesForFreeDelivery = prices.filter((rule) => rule.DeliveryPrice === 0)

  if (rulesForFreeDelivery.length === 0) return 0

  return Math.min(...rulesForFreeDelivery.map((rule) => rule.SumFrom))
})

async function nextStep(): Promise<void> {
  const messagesToShow = (clientStore.ClientState?.data?.MessagesV2 ?? []).filter(
    (m: MessagesV2) =>
      m.Type === MessageType.Important || m.Type === MessageType.ImportantCustom
  )

  if (messagesToShow && messagesToShow.length > 0) {
    for (const message of messagesToShow) {
      popupStore.showWarning(message.Text, 'important-message', message.Title)
    }
  } else {
    navigateTo(appConfig.VueSettingsPreRun.Links.CartFirstStep)
  }
}

const partialPayEnabled = computed<boolean>(
  () => clientStore.ClientState.data?.PartialPayState?.PartialPayEnabled ?? false
)

const giftsRangedVisible = computed<boolean>(
  () => (menuStore.GiftsRanged.data?.Gifts?.length ?? 0) > 0
)

const giftsBirthdayVisible = computed(() => {
  const birthdayData = clientStore.ClientState.data?.BirthDayPresents

  if (!birthdayData) {
    return false
  }

  return (
    birthdayData.BirthdayGiftIsEnabled &&
    birthdayData.ClientDataFilled &&
    birthdayData.InBirthdayPeriod &&
    !birthdayData.AnnualLimitReached
  )
})
</script>

<style lang="scss">
@use '~/assets/mixins';
@use '~/assets/variables';

.v-sidebar-content {
  position: relative;
  height: 100%;

  &__back {
    padding: 0.55rem 1.145rem 0.145rem;
  }

  &__close {
    position: absolute;
    top: 28px;
    left: -42px;
  }

  &--default {
    height: 100%;
    max-height: 100%;

    @include mixins.sm {
      max-height: calc(100% - 2.75rem);
    }
  }

  &__wrapper {
    overflow-y: auto;
    overflow-x: hidden; //for animation
  }

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }

  &__empty-icon {
    width: 250px;
    height: 171px;
    flex-shrink: 0;
  }

  &__top {
    padding: 2.285rem 2.145rem 1.715rem 1.715rem;

    @include mixins.sm {
      padding: 1.43rem 1.145rem;
    }
  }

  &__messages > .v-cart-sidebar-messages-wrapper {
    padding: 0 2.145rem 1.715rem 1.715rem;

    @include mixins.sm {
      padding: 0 1.145rem 1.43rem;
    }
  }

  &__gifts-ranged {
    padding: 0 2.145rem 0.855rem 1.715rem;

    @include mixins.sm {
      padding: 0 1.145rem 0.57rem;
    }
  }

  &__items {
    padding: 0.855rem 0 0;
  }

  &__gifts-birthday {
    padding: 0 2.145rem 1.715rem 1.715rem;

    @include mixins.sm {
      padding: 0 1.145rem 0.57rem;
    }
  }

  &__recommended {
    padding: 0.855rem 0 0;
  }

  &__promo-code {
    background: variables.$Mono0;
    padding: 0.57rem 2.145rem 0.57rem 1.715rem;
    margin-bottom: 0.285rem;
    box-shadow: variables.$CardShadow;
  }

  &__partial_points {
    background: variables.$Mono0;
    padding: 0.715rem 1.715rem;
    margin-bottom: 1.145rem;
    box-shadow: variables.$CardShadow;
  }

  &__summary {
    padding: 0.855rem 2.145rem 0.855rem 1.715rem;
    background: variables.$Mono0;
    border-radius: variables.$BorderRadius variables.$BorderRadius 0 0;

    @include mixins.sm {
      padding: 0.855rem 1.145rem;
    }
  }

  &__next-step {
    padding: 1.145rem 1.715rem;
    background: variables.$Mono0;

    @include mixins.sm {
      padding: 1.145rem;
    }
  }

  &__fixed-part {
    box-shadow: variables.$CardShadow;
    border-radius: variables.$BorderRadiusButton variables.$BorderRadiusButton 0 0;
    min-height: fit-content;
  }
}
</style>
